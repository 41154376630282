[data-reach-dialog-overlay] {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 101;
    overflow: auto;
    position: fixed;
    display: flex;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.2);
    flex-direction: column;
    outline: none;
    transition: opacity 300ms cubic-bezier(0, 0, 0.2, 1);
}
