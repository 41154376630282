:root {
    --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);

    --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
    --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
    --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
    --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
    --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);

    --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
    --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
    --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
    --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

html,
body {
    height: 100%;
    width: 100%;
    line-height: 1.15;
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-family: 'proxima-nova-alt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

#root {
    min-height: 100vh;
    position: relative;
}

#overlays {
    position: static !important;
    z-index: 9999;
}

#overlays > * {
    z-index: 9999;
}

* {
    box-sizing: border-box;
    position: relative;

    -webkit-user-select: none;
    user-select: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    -webkit-tap-highlight-color: transparent;

    margin: 0;
    padding: 0;
}

::selection {
    background: #9ae1df; /* WebKit/Blink Browsers */
    color: black;
}

button {
    appearance: none;
    cursor: pointer;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

@keyframes fromBottom {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fromLeft {
    from {
        transform: translate3d(-30px, 0, 0);
    }
    to {
        transform: translateZ(0);
    }
}

@keyframes fromRight {
    from {
        transform: translate3d(30px, 0, 0);
    }
    to {
        transform: translateZ(0);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fade-enter > div {
    z-index: 1;
    animation: 0.3s fade ease;
}

.back-enter > div {
    z-index: 1;
    animation: 0.3s fromLeft ease, 0.5s fade ease;
}

.forward-enter > div {
    z-index: 1;
    animation: 0.3s fromRight ease, 0.5s fade ease;
}

reach-portal {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10000;
}
reach-portal > * {
    z-index: 10000;
}

body > .sorting-row > td:not(:first-child) {
    display: none;
}

body .WorkperiodDay,
body .WorkOrder,
body .WorkPeriod button {
    cursor: pointer;
}

body.erase .WorkperiodDay,
body.erase .ShiftPeriod,
body.erase {
    cursor: url('/cursors/eraser.svg'), auto;
}

body.cut .WorkPeriod button,
body.cut .WorkOrder,
body.cut {
    cursor: url('/cursors/scissor.svg'), auto;
}
